import {
    useCallback,
    useContext,
    useMemo,
    useEffect,
    useState,
    useRef,
  } from 'react'
  import { WindowContext } from '../../contexts/WindowProvider'
  import { SurveyContext } from '../../contexts/SurveyProvider'
  import { recodeTypes } from '../../pages/VariablesPage'
  import AutoSizer from 'react-virtualized-auto-sizer'
  import {
    Button,
    Classes,
    DialogBody,
    DialogStep,
    FormGroup,
    Icon,
    Menu as BpMenu,
    MenuDivider,
    MenuItem,
    MultistepDialog,
    TextArea,
    Tag,
    Alert,
    EditableText,
    Collapse,
    InputGroup,
    Checkbox,
  } from '@blueprintjs/core'
  import variableOrQuestionToString from '../../utils/variableOrQuestionToString'
  import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
  import { Select2 } from '@blueprintjs/select'
  import highlightText from '../../utils/highlightText'
  import './CodeOpenEndDialog.css'
  import { useApi } from '../../contexts/ApiProvider'
  import { useParams } from 'react-router-dom'
  import { AppToaster, ProgressToaster } from '../toaster'
  import { errorObjectToString } from '../../ApiClient'
  import { useUser } from '../../contexts/UserProvider'
  import { LoadingIcon } from '../LoadingSpinner'
  import { downloadFile } from '../../utils/downloadFile'
  import getContrastText, { lighten } from '../../utils/getContrastRatio'
  import { interpolatePRGn } from 'd3-scale-chromatic'
  import { Duration } from '../TimeAgo'
  
  export default function CodeOpenEndDialogV2({ isOpen, onClose }) {
    const { setLoading } = useContext(WindowContext)
    const {
      user: { notifications },
    } = useUser()
    const [taskId, setTaskId] = useState()
    const [previewData, setPreviewData] = useState()
    const [maxComments, setmaxComments] = useState()
    const [startRespondent, setstartRespondent] = useState()
    const [codeAllTheThings, setCodeAllTheThings] = useState(false)
    const task = notifications?.find(n => n.payload.task_id === taskId)
    const progress = task ? task.payload.progress / 100 : undefined
    const status = task ? task.payload.progress_message : undefined
    const audienceInput = useRef()
    const topicInput = useRef()
    const api = useApi()
    const { surveyId } = useParams()
    const { surveyDialogOpen, setSurveyDialogOpen, variables, nRespondents } =
      useContext(SurveyContext)
    const type = surveyDialogOpen
      ? recodeTypes.find(r => r?.name === surveyDialogOpen.split('-')[1])
      : undefined
  
    const [confirmAlertOpen, setConfirmAlertOpen] = useState(false)
  
    const [numRespondents, setNumRespondents] = useState(2)
    const [selectedVariable, setSelectedVariable] = useState()
    const [selectedRespondent, setSelectedRespondent] = useState()
    const [codes, setCodes] = useState()
    const [instructions, setInstructions] = useState(
      'You will receive comments from property owners about their experience with the rental platform VRBO'
    )
    const [topic, setTopic] = useState(
      'their experience with the rental platform VRBO'
    )
    const [audience, setAudience] = useState('property owners')
    const [prevSubmission, setPrevSubmission] = useState()
    const [currentRespondent, setCurrentRespondent] = useState()
    const textVariables = variables.filter(v => v.variable_type === 'text')
    const intVariables = [{name: "No Respondent ID", id: null}].concat(
        variables.filter(v => v.variable_type === 'number' || v.variable_type === 'text')
    )
    const filterVariable = (query, variable, _index, exactMatch) => {
      const normalizedName = variable.name.toLowerCase()
      const normalizedQuery = query.toLowerCase()
  
      if (exactMatch) {
        return normalizedQuery === normalizedName
      } else {
        return normalizedName.indexOf(normalizedQuery) >= 0
      }
    }
    const variableRenderer = (
      variable,
      { handleClick, handleFocus, modifiers, query }
    ) => {
      if (!modifiers.matchesPredicate) {
        return null
      }
      const text = variableOrQuestionToString(variable)
      return (
        <MenuItem
          selected={modifiers.active}
          shouldDismissPopover={true}
          disabled={modifiers.disabled}
          label={variable.type}
          key={variable.id}
          onClick={handleClick}
          onFocus={handleFocus}
          text={highlightText(text, query)}
        />
      )
    }

    const handleCheckboxChange = () => {
        setCodeAllTheThings(prevState => !prevState)
    }

    const errors = useMemo(() => {
      const request = {
        variable: selectedVariable,
        instructions,
        codes: codes
          ? codes
              .split('\n')
              .map(code => code.trim())
              .filter(code => code.length > 0)
          : [],
        num_respondents: numRespondents,
        start_respondent: startRespondent,
        code_all_the_things: codeAllTheThings,
        survey_id: surveyId,
      }
      const errors = {}
      if (request.variable === undefined) {
        errors.variable = 'No variable selected'
      }
      // if (request.instructions.length < 10) {
      //   errors.instructions = 'Instructions are too short'
      // }
      if (!request.codes || request.codes.length < 2) {
        errors.codes = 'Not enough codes'
      }
      if (request.numRespondents < 1) {
        errors.numRespondents = 'Not enough respondents'
      } else if (
        request.numRespondents > nRespondents ||
        request.startRespondent + request.numRespondents > nRespondents
      ) {
        errors.numRespondents = `Too many respondents. Only ${nRespondents} in survey.`
      }
      return errors
    }, [
      codes,
      instructions,
      nRespondents,
      numRespondents,
      selectedVariable,
      startRespondent,
      surveyId,
    ])
    function getOpenEndRequest() {
        const result = {
          variable: selectedVariable?.id,
          instructions,
          codeAllTheThings: codeAllTheThings,
          codes: codes
            ? codes
                .split('\n')
                .map(code => code.trim())
                .filter(code => code.length > 0)
            : [],
          survey_id: surveyId,
          // threshold: cutoff,
        };

        if (selectedRespondent && selectedRespondent.id) {
          result.respondent = selectedRespondent.id;
        }

        if (maxComments) {
          result.maxComments = maxComments;
        }

        if (startRespondent) {
          result.startRespondent = startRespondent
        }
      
        return result;
      }

    const hasChanged =
      prevSubmission === undefined ||
      JSON.stringify(getOpenEndRequest()) !== prevSubmission
  
    const handleFullRunRequest = async () => {
      const request = getOpenEndRequest(false)
      const response = await api.post(
        `/survey/${surveyId}/variables/code_oes/queue/full_run_v2`,
        request
      )
      setConfirmAlertOpen(false)
      setSurveyDialogOpen()
      if (response.ok) {
        AppToaster.show({
          message:
            "Open End Coding Requested. You'll receive an email with the results when completed.",
          intent: 'success',
          icon: 'tick-circle',
        })
      } else {
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({ message, intent: 'danger', icon: 'error' })
      }
      setLoading(false)
    }
    const downloadTaskData = useCallback(async () => {
      if (!taskId) return
      const toasterId = ProgressToaster.show({
        message: 'Downloading task data...',
        intent: 'primary',
        icon: <LoadingIcon />,
        timeout: 0,
      })
      const response = await api.get(`/task/${taskId}/download`, undefined, {
        cache: 'no-store',
      })
      ProgressToaster.dismiss(toasterId)
      if (response.ok) {
        const data = await response.body.blob()
        console.log({ data })
        downloadFile(data, `${taskId}.zip`)
        ProgressToaster.show({
          message: 'Download Successful',
          isCloseButtonShown: false,
          icon: 'tick-circle',
          intent: 'success',
        })
        setHasDownloaded(true)
      } else {
        const message = `Error exporting (${response.status} - ${response?.body?.error})`
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
    }, [api, taskId])
  
    const getPreviewData = useCallback(async () => {
      if (!taskId) return
      const toasterId = ProgressToaster.show({
        message: 'Downloading task data...',
        intent: 'primary',
        icon: <LoadingIcon />,
        timeout: 0,
      })
      const response = await api.get(
        `/survey/${surveyId}/variables/code_oes/preview/${taskId}`
      )
      ProgressToaster.dismiss(toasterId)
      console.log({ response })
      if (response.ok) {
        return response.body
      } else {
        const message = `Error exporting (${response.status} - ${response?.body?.error})`
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
    }, [api, surveyId, taskId])
  
    useEffect(() => {
      ;(async () => {
        if (previewData || progress !== 1) {
          return
        }
        const data = await getPreviewData()
        console.log(data)
        setPreviewData(data)
        setCurrentRespondent(0)
      })()
    }, [getPreviewData, previewData, progress])
  
    const respondentData = previewData
      ? (previewData?.respondents?.[currentRespondent]?.probabilities || [])
          .map((prob, i) => ({ prob, code: previewData.codes[i] }))
          .sort((a, b) => b.prob - a.prob)
      : undefined
  
    const respHasError = previewData
      ? previewData?.respondents?.[currentRespondent]?.error || false
      : false
  
    const [cutoff, setCutoff] = useState(0.7)
    const [hasDownload, setHasDownloaded] = useState(false)
  
    const codeRenderer = ({ prob, code }, i) => {
      let backgroundColor, color
      backgroundColor = lighten(interpolatePRGn(prob), 0)
      color = getContrastText(backgroundColor, 3)
      return (
        <Tooltip2 content={(prob * 100).toFixed(1) + '%'} minimal compact key={i}>
          <Tag
            className="code-tag"
            style={{
              color,
              backgroundColor,
              maxWidth: 400,
            }}
          >
            {code}
          </Tag>
        </Tooltip2>
      )
    }
  
    const [customPromptOpen, setCustomPromptOpen] = useState(false)
  
    return (
      <>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <MultistepDialog
                transitionDuration={surveyDialogOpen ? 0 : 300}
                finalButtonProps={{
                  text: 'Code Open End',
                  intent: 'success',
                  onClick: () => {
                    setConfirmAlertOpen(true)
                  },
                }}
                style={{ width: width - 100, height: height - 100 }}
                onClose={onClose}
                onOpened={() => {
                  audienceInput.current.handleFocus()
                }}
                title={
                  <Popover2
                    minimal
                    placement="bottom-start"
                    content={
                      <BpMenu>
                        {recodeTypes.map((recode, i) =>
                          recode !== null ? (
                            <MenuItem
                              key={i}
                              icon={recode.icon}
                              text={recode.name}
                              labelElement={
                                <Icon
                                  icon={type === recode ? 'small-tick' : 'blank'}
                                />
                              }
                              onClick={() =>
                                setSurveyDialogOpen(
                                  `CreateVariableDialog-${recode.name}`
                                )
                              }
                            />
                          ) : (
                            <MenuDivider key={i} />
                          )
                        )}
                      </BpMenu>
                    }
                  >
                    <Button
                      minimal
                      text={type?.name}
                      rightIcon="caret-down"
                      icon={type?.icon}
                    />
                  </Popover2>
                }
                isOpen={isOpen}
                isCloseButtonShown={true}
              >
                <DialogStep
                  id="prompt"
                  title="Prompt"
                  panel={
                    <DialogBody id="setup-panel">
                      <Button
                        minimal
                        // icon="edit"
                        id="custom-prompt-toggle"
                        icon={
                          customPromptOpen ? (
                            'circle'
                          ) : (
                            <Icon icon="tick-circle" color="black" />
                          )
                        }
                        text="Templated Prompt"
                        small
                        onClick={() => setCustomPromptOpen(prev => !prev)}
                        style={{
                          ...(!customPromptOpen ? { color: 'black' } : null),
                        }}
                      />
                      <p
                        id="prompt-template"
                        className={
                          (customPromptOpen ? 'inactive ' : '') +
                          Classes.TEXT_MUTED
                        }
                        style={{ padding: 12 }}
                      >
                        You will receive comments from{' '}
                        <EditableText
                          className="inline-editable-prompt-text"
                          value={audience}
                          onChange={value => {
                            setAudience(value)
                            setInstructions(
                              `You will receive comments from ${
                                value ? value : 'audience members'
                              } about ${topic ? topic : 'the topic'}.`
                            )
                          }}
                          selectAllOnFocus
                          ref={audienceInput}
                          placeholder="audience"
                          minWidth={0}
                          disabled={customPromptOpen}
                        />
                        about{' '}
                        <EditableText
                          className="inline-editable-prompt-text"
                          value={topic}
                          onChange={value => {
                            setTopic(value)
                            setInstructions(
                              `You will receive comments from ${
                                audience ? audience : 'audience members'
                              } about ${value ? value : 'the topic'}.`
                            )
                          }}
                          selectAllOnFocus
                          ref={topicInput}
                          placeholder="topic"
                          minWidth={0}
                          disabled={customPromptOpen}
                        />
                      </p>
                      <Button
                        minimal
                        id="custom-prompt-toggle"
                        icon={
                          !customPromptOpen ? (
                            'circle'
                          ) : (
                            <Icon icon="tick-circle" color="black" />
                          )
                        }
                        text="Custom Prompt"
                        small
                        onClick={() => setCustomPromptOpen(prev => !prev)}
                        style={{
                          marginTop: 14,
                          ...(customPromptOpen ? { color: 'black' } : null),
                        }}
                      />
                      <Collapse keepChildrenMounded isOpen={customPromptOpen}>
                        <div style={{ padding: 12 }}>
                          <TextArea
                            growVertically
                            rows={10}
                            className={Classes.INPUT}
                            small
                            fill
                            value={instructions}
                            onChange={ev => setInstructions(ev.target.value)}
                          />
                        </div>
                      </Collapse>
                    </DialogBody>
                  }
                />
                <DialogStep
                  id="variable"
                  title="Variables"
                  nextButtonProps={selectedVariable ? {} : { disabled: true }}
                  panel={
                    <DialogBody id="variable-panel">
                      <FormGroup
                        label="Open End Variable to Code - required"
                        labelFor="variable"
                      >
                        <Select2
                          id="variable"
                          items={textVariables}
                          itemPredicate={filterVariable}
                          itemRenderer={variableRenderer}
                          onItemSelect={setSelectedVariable}
                          selectedItem={selectedVariable}
                          noResults={
                            <MenuItem
                              disabled={true}
                              text="No results."
                              roleStructure="listoption"
                            />
                          }
                        >
                          <Button
                            text={
                              selectedVariable
                                ? variableOrQuestionToString(selectedVariable)
                                : 'Select a variable'
                            }
                            rightIcon="double-caret-vertical"
                            icon="manually-entered-data"
                            placeholder="Select a variable"
                            style={{ backgroundColor: 'white' }}
                          />
                        </Select2>
                      </FormGroup>
                      <FormGroup
                        label="Variable that maps to respondent's ID. HIGHLY RECOMMENDED."
                        labelFor="respondent"
                      >
                        <Select2
                          id="respondent"
                          items={intVariables}
                          itemPredicate={filterVariable}
                          itemRenderer={variableRenderer}
                          onItemSelect={setSelectedRespondent}
                          selectedItem={selectedRespondent}
                          noResults={
                            <MenuItem
                              disabled={true}
                              text="No results."
                              roleStructure="listoption"
                            />
                          }
                        >
                          <Button
                            text={
                              selectedRespondent
                                ? variableOrQuestionToString(selectedRespondent)
                                : 'Select a variable'
                            }
                            rightIcon="double-caret-vertical"
                            icon="manually-entered-data"
                            placeholder="Select a variable"
                            style={{ backgroundColor: 'white' }}
                          />
                        </Select2>
                      </FormGroup>
                      <FormGroup
                        label="Maximum items to code. If left blank, all items will be coded."
                      >
                        <InputGroup
                          id="maxComments"
                          type="number"
                          value={maxComments}
                          onChange={ev => setmaxComments(parseInt(ev.target.value))}
                        />
                      </FormGroup>
                      <FormGroup
                        label="Respondent to start with. If left blank, the first respondent will be used."
                      >
                        <InputGroup
                          id="startRespondent"
                          type="number"
                          value={startRespondent}
                          onChange={ev => setstartRespondent(parseInt(ev.target.value))}
                        />
                      </FormGroup>
                      <FormGroup
                        helperText={<div>By default, certain comments/verbatims (nan, n/a, na, no answer, don't know, dk, none, nothing, all, everything)
                          will be assigned to categories "No answer", "None", "All", and "Don't know" without
                          being sent to openai. However, if you would like the ai to try to process these comments, check this box.</div>}
                      >
                        <Checkbox
                          checked={codeAllTheThings}
                          onChange={handleCheckboxChange}
                        >
                          Skip manual coding.
                        </Checkbox>
                      </FormGroup>
                    </DialogBody>
                  }
                />
                <DialogStep
                  id="codes"
                  title="Codes"
                  nextButtonProps={{
                    onMouseDown: ev => {
                      if (hasChanged) {
                        setCurrentRespondent()
                        // setPreviewData()
                        setTaskId()
                        if (Object.keys(errors).length > 0) {
                          console.log('errors', errors)
                          ev.preventDefault()
                          ev.stopPropagation()
                          return
                        }
                      }
                    },
                    disabled: Object.keys(errors).length > 0,
                    text: "Next",
                  }}
                  panel={
                    <DialogBody id="options-panel" className="options-panel">
                      <FormGroup
                        label="Codes"
                        labelFor="codes-input"
                        subLabel="Enter one code per line. Use a consistent format for all codes."
                      >
                        <TextArea
                          id="codes-input"
                          growVertically
                          rows={17}
                          className={Classes.INPUT}
                          style={{ textWrap: 'nowrap' }}
                          placeholder="Positive - Form factor and aesthetic looking technology"
                          small
                          fill
                          onChange={ev => setCodes(ev.target.value)}
                          value={codes}
                        />
                      </FormGroup>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 6,
                        }}
                      >
                      </div>
                      <div>
                        <Alert
                          isOpen={confirmAlertOpen}
                          loading={false}
                          cancelButtonText="Cancel"
                          confirmButtonText="Code Survey"
                          onConfirm={handleFullRunRequest}
                          onCancel={() => setConfirmAlertOpen(false)}
                          cancel
                          intent="success"
                        >
                          {
                            <p>
                              You are about to enqueue a coding job. Once the job is picked up,
                              it will take around 1-2 minutes per 1,000 items to be coded.
                            </p>
                          }
                        </Alert>
                      </div>
                    </DialogBody>
                  }
                />
              </MultistepDialog>
            )
          }}
        </AutoSizer>
      </>
    )
  }
  