import { Button, Checkbox, Icon, Spinner, SpinnerSize } from '@blueprintjs/core'
import chroma from 'chroma-js'
import { useCallback, useState } from 'react'
import { isFloat } from '../../utils/tryConvertString'
import './TURFTable.css'

import { useTURF } from '../../contexts/TURFProvider'
import { useMaxDiff } from '../../contexts/MaxDiffProvider'

function rgbToYiq(r, g, b) {
  return 0.299 * r + 0.587 * g + 0.114 * b
}

function getTextColorForBackground(backgroundColor) {
  const [r, g, b] = chroma(backgroundColor).rgb()
  const yiq = rgbToYiq(r, g, b)

  return yiq >= 128 ? 'black' : 'white'
}

const percentageFormatter = new Intl.NumberFormat('en', {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

const sortCarets = { asc: 'caret-up', desc: 'caret-down' }

export default function TURFTable({ useLabels = true }) {
  const { TURFResults, claimIds, mode } = useTURF()

  const { selectedClaimsIds, consideredClaimsIds } = claimIds

  const [sortOrder, setSortOrder] = useState('asc')
  const [sortColumn, setSortColumn] = useState('index')

  const onClickOrder = useCallback(column => {
    setSortColumn(() => column)
    setSortOrder(prev => (prev === 'asc' ? 'desc' : 'asc'))
  }, [])

  const workingClaims = TURFResults.map((claim, index) => {
    const is_selected = selectedClaimsIds.includes(claim.variable.id)
    const is_considered = consideredClaimsIds.includes(claim.variable.id)
    const reach =
      mode === 'build'
        ? is_selected
          ? -1
          : claim.deduplicated_reach
        : is_selected
        ? claim.reach
        : -1

    return {
      index: index,
      favourite: claim.favourite,
      id: claim.variable.id,
      name:
        useLabels && claim.variable.label
          ? claim.variable.label
          : claim.variable.name,
      reach: reach,
      is_selected: is_selected,
      is_considered: is_considered,
    }
  })

  const sortFunction = (a, b) => {
    if (a[sortColumn] === null) return 1
    if (b[sortColumn] === null) return -1
    if (a[sortColumn] === null && b[sortColumn] === null) return 0
    if (isFloat(a[sortColumn]) && isFloat(b[sortColumn])) {
      return (a[sortColumn] - b[sortColumn]) * (sortOrder === 'asc' ? 1 : -1)
    }

    return (
      a[sortColumn]
        .toString()
        .localeCompare(b[sortColumn].toString(), 'en', { numeric: true }) *
      (sortOrder === 'asc' ? 1 : -1)
    )
  }

  const max_reach =
    Math.max(
      ...workingClaims
        .filter(
          claim =>
            claim.is_considered &&
            claim.reach !== undefined &&
            claim.reach !== -1
        )
        .map(claim => claim.reach)
    ) || 1

  const reach_color_scale = chroma
    .scale(['red', 'white', 'green'])
    .domain([0, max_reach])

  const max_favourite =
    Math.max(
      ...workingClaims
        .filter(
          claim =>
            (mode === 'build' ? !claim.is_selected : claim.is_selected) &&
            claim.is_considered &&
            claim.favourite !== undefined
        )
        .map(claim => claim.favourite)
    ) || 1

  const favourite_color_scale = chroma
    .scale(['red', 'white', 'green'])
    .domain([0, max_favourite])

  return (
    <>
      {workingClaims === undefined ? (
        <Spinner />
      ) : (
        <div className="turf-table-scrollable-container">
          <table className="turf-table">
            <TURFTableHeader
              sortOrder={sortOrder}
              sortColumn={sortColumn}
              onClickOrder={onClickOrder}
            />

            <tbody>
              {workingClaims.sort(sortFunction).map(workingClaim => (
                <TURFTableRow
                  key={workingClaim.id}
                  reach_color_scale={reach_color_scale}
                  favourite_color_scale={favourite_color_scale}
                  workingClaim={workingClaim}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}
const TURFTableHeaderRow = ({ sortOrder, sortColumn, onClickOrder }) => {
  const { TURFResults, claimIds } = useTURF()
  const { maxDiffShowFavorites } = useMaxDiff()
  const { selectedClaimsIds, consideredClaimsIds } = claimIds

  const claimsLength = TURFResults.length
  const sortIcon = column => {
    if (sortColumn === column) {
      return <Icon icon={sortCarets[sortOrder]} />
    } else {
      return <Icon icon="double-caret-vertical" color="grey" />
    }
  }

  return (
    <tr>
      <th onClick={() => onClickOrder('index')}>
        {'N°'}
        {sortIcon('index')}
      </th>
      <th>
        <SelectAllNoneCheckbox />
      </th>
      <th>
        <ConsiderAllNoneCheckbox />
      </th>
      <th onClick={() => onClickOrder('name')}>
        {'Items'}
        {sortIcon('name')}
      </th>
      <th onClick={() => onClickOrder('reach')}>
        {'Reach'}
        {sortIcon('reach')}
      </th>
      {maxDiffShowFavorites && (
        <th onClick={() => onClickOrder('favourite')}>
          {'Favorite'}
          {sortIcon('favourite')}
        </th>
      )}
    </tr>
  )
}

const TURFTableHeader = ({ sortOrder, sortColumn, onClickOrder }) => {
  return (
    <thead>
      <TURFTableHeaderRow
        sortOrder={sortOrder}
        sortColumn={sortColumn}
        onClickOrder={onClickOrder}
      />
    </thead>
  )
}

function TURFTableHeaderButtonsRow() {
  return (
    <>
      <tr className="turf-table-buttons-row">
        <th></th>
        <th>
          <SelectAllButton />
        </th>
        <th>
          <ConsiderAllButton />
        </th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tr className="turf-table-buttons-row">
        <th></th>
        <th>
          <SelectNoneButton />
        </th>
        <th>
          <ConsiderNoneButton />
        </th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </>
  )
}

const TURFTableRow = ({
  reach_color_scale,
  favourite_color_scale,
  workingClaim,
}) => {
  const {
    index,
    id,
    name,
    is_selected: isSelected,
    is_considered,
    favourite,
    reach,
  } = workingClaim

  const {
    loading,
    mode,
    selectClaims,
    deselectClaims,
    considerClaims,
    deconsiderClaims,
  } = useTURF()

  const { maxDiffShowFavorites } = useMaxDiff()

  const reach_color = reach_color_scale(reach).hex()
  const favourite_color = favourite_color_scale(favourite).hex()

  let reachBackground,
    reachNumber,
    reachNumberColour,
    favouriteBackground,
    favouriteNumber,
    favouriteNumberColour

  const className = isSelected ? 'selected-row' : ''

  if (mode === 'build') {
    reachBackground = isSelected ? null : reach_color
    reachNumberColour =
      reachBackground && getTextColorForBackground(reachBackground)
    reachNumber = isSelected ? '-' : `+${percentageFormatter.format(reach)}`

    favouriteBackground = isSelected ? null : favourite_color
    favouriteNumberColour =
      favouriteBackground && getTextColorForBackground(favouriteBackground)
    favouriteNumber = isSelected
      ? '-'
      : `+${percentageFormatter.format(favourite)}`
  } else {
    reachBackground = isSelected ? reach_color : null
    reachNumberColour =
      reachBackground && getTextColorForBackground(reachBackground)
    reachNumber = isSelected ? `${percentageFormatter.format(reach)}` : '-'

    favouriteBackground = isSelected ? favourite_color : null
    favouriteNumberColour =
      reachBackground && getTextColorForBackground(favouriteBackground)
    favouriteNumber = isSelected
      ? `${percentageFormatter.format(favourite)}`
      : '-'
  }

  return (
    <tr key={id} className={className}>
      <td>{index + 1}</td>
      <td>
        <input
          type="checkbox"
          onChange={e =>
            e.target.checked ? selectClaims([id]) : deselectClaims([id])
          }
          disabled={loading || !is_considered}
          checked={isSelected}
          className={
            isSelected ? 'select-checkbox-checked' : 'select-checkbox-unchecked'
          }
        />
      </td>
      <td>
        <input
          type="checkbox"
          onChange={e =>
            e.target.checked ? considerClaims([id]) : deconsiderClaims([id])
          }
          checked={is_considered}
          disabled={loading}
          className="consider-checkbox"
        />
      </td>
      <td>{name}</td>
      {loading ? (
        <>
          <td>
            <Spinner size={SpinnerSize.SMALL} />
          </td>
          <td>
            <Spinner size={SpinnerSize.SMALL} />
          </td>
        </>
      ) : (
        <>
          <td bgcolor={reachBackground} style={{ color: reachNumberColour }}>
            {reachNumber}
          </td>
          {maxDiffShowFavorites && (
            <td
              bgcolor={favouriteBackground}
              style={{ color: favouriteNumberColour }}
            >
              {favouriteNumber}
            </td>
          )}
        </>
      )}
    </tr>
  )
}

function SelectAllButton() {
  const { loading, selectClaims, TURFResults, claimIds } = useTURF()

  const { selectedClaimsIds, consideredClaimsIds } = claimIds

  const selectAll = useCallback(() => {
    selectClaims(TURFResults.map(c => c.variable.id))
  }, [TURFResults, selectClaims])

  return (
    <Button
      onClick={selectAll}
      loading={loading}
      small={true}
      disabled={selectedClaimsIds.length === consideredClaimsIds.length}
      className="turf-button"
    >
      Select All
    </Button>
  )
}

function SelectNoneButton() {
  const { loading, deselectClaims, claimIds } = useTURF()

  const { selectedClaimsIds } = claimIds

  const deselectAll = useCallback(() => {
    deselectClaims(selectedClaimsIds)
  }, [deselectClaims, selectedClaimsIds])

  return (
    <Button
      onClick={deselectAll}
      loading={loading}
      small={true}
      disabled={selectedClaimsIds.length === 0}
      className="turf-button"
    >
      Select None
    </Button>
  )
}

function ConsiderAllButton() {
  const { loading, claimIds, setClaimIds, TURFResults } = useTURF()

  const { consideredClaimsIds } = claimIds
  const considerAll = useCallback(() => {
    setClaimIds(prev => ({
      ...prev,
      consideredClaimsIds: TURFResults.map(c => c.variable.id),
    }))
  }, [TURFResults, setClaimIds])

  return (
    <Button
      onClick={considerAll}
      loading={loading}
      small={true}
      disabled={consideredClaimsIds.length === TURFResults.length}
      className="turf-button consider-all-button"
    >
      Consider All
    </Button>
  )
}

function ConsiderNoneButton() {
  const { loading, claimIds, deconsiderClaims, TURFResults } = useTURF()

  const { consideredClaimsIds } = claimIds

  const deconsiderAll = useCallback(() => {
    deconsiderClaims(TURFResults.map(c => c.variable.id))
  }, [TURFResults, deconsiderClaims])

  return (
    <Button
      onClick={deconsiderAll}
      loading={loading}
      small={true}
      disabled={consideredClaimsIds.length === 0}
      className="turf-button consider-none-button"
    >
      Consider None
    </Button>
  )
}

function ConsiderAllNoneCheckbox() {
  const { loading, claimIds, setClaimIds, deconsiderClaims, TURFResults } =
    useTURF()
  const { consideredClaimsIds } = claimIds

  const considerAll = useCallback(() => {
    setClaimIds(prev => ({
      ...prev,
      consideredClaimsIds: TURFResults.map(c => c.variable.id),
    }))
  }, [TURFResults, setClaimIds])

  const deconsiderAll = useCallback(() => {
    deconsiderClaims(TURFResults.map(c => c.variable.id))
  }, [TURFResults, deconsiderClaims])

  const indeterminate =
    consideredClaimsIds.length > 0 &&
    consideredClaimsIds.length < TURFResults.length

  const considered = consideredClaimsIds.length
  const total = TURFResults.length

  const labelText = `(${considered}/${total})`

  return (
    <Checkbox
      onChange={e => (e.target.checked ? considerAll() : deconsiderAll())}
      checked={consideredClaimsIds.length === TURFResults.length}
      indeterminate={indeterminate}
      disabled={loading}
      className="turf-consider-all-none-checkbox"
      label={`Considered ${labelText}`}
      inline={true}
      style={{ display: 'flex', alignItems: 'center' }}
    />
  )
}

function SelectAllNoneCheckbox() {
  const { loading, deselectClaims, selectClaims, TURFResults, claimIds } =
    useTURF()

  const { selectedClaimsIds, consideredClaimsIds } = claimIds

  const selectAll = useCallback(() => {
    selectClaims(TURFResults.map(c => c.variable.id))
  }, [TURFResults, selectClaims])

  const deselectAll = useCallback(() => {
    deselectClaims(selectedClaimsIds)
  }, [deselectClaims, selectedClaimsIds])

  const selected = selectedClaimsIds?.length
  const considered = consideredClaimsIds?.length

  const indeterminate =
    selected !== undefined &&
    considered !== undefined &&
    selected > 0 &&
    selected < considered

  const labelText =
    selected !== undefined && considered !== undefined
      ? `(${selected}/${considered})`
      : ''

  return (
    <Checkbox
      onChange={e => (e.target.checked ? selectAll() : deselectAll())}
      checked={selectedClaimsIds?.length === TURFResults?.length}
      indeterminate={indeterminate}
      disabled={loading}
      className="turf-select-all-none-checkbox"
      label={`Selected ${labelText}`}
      style={{ display: 'flex', alignItems: 'center' }}
    />
  )
}
